import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import MarkdownView from "react-showdown"

import { PostContainer, PostHeader } from "../components/styled/post.js"
import { Container } from "../components/styled/container"

const Terms = ({ data }) => {
  return (
    <Layout>
      <Container main>
        <SEO
          title="Privacy Policy - FGCharts"
          description={data.strapiSeo.termspage}
        />
        <PostHeader>
          <h2 style={{ marginBottom: 16 }}>Privacy Policy of FGCharts</h2>
        </PostHeader>
        <PostContainer>
          <MarkdownView
            markdown={data.allStrapiPolicy.edges[0].node.copy}
            options={{ table: true, emoji: true }}
          />
        </PostContainer>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiPolicy {
      edges {
        node {
          copy
        }
      }
    }
    strapiSeo {
      termspage
    }
  }
`

export default Terms
